@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Light.ttf') format('opentype');
  font-weight: 200; /* light */
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Regular.ttf') format('opentype');
  font-weight: normal; /* 400 */
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Medium.ttf') format('opentype');
  font-weight: 500; /* medium */
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('opentype');
  font-weight: 600; /* semi-bold */
}
@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/Poppins/Poppins-Bold.ttf') format('opentype');
  font-weight: bold; /* 700 */
}
@font-face {
  font-family: 'Roboto';
  src: url('/assets/fonts/Roboto/Roboto-Medium.ttf') format('opentype');
  font-weight: 500; /* medium */
}
